import { BoundedContent, SwanStyleProps } from '@vp/swan'
import React from 'react'
interface MainContentProps extends SwanStyleProps {
  children: React.ReactNode
}
export const MainContent = ({ children, ...props }:MainContentProps) => {
  return (
    <BoundedContent
      px={{ xs: 5, md: 7 }}
      {...props}
    >
      {children}
    </BoundedContent>
  )
}
