import { JSX } from 'react'
import { Box, Spinner } from '@vp/swan'

// Declare the global 'window' object
declare const window: any

const redirect = async (
  redirectPath: Promise<string> | string
): Promise<void> => {
  // Gatsby build doesn't have a window, need to check here
  if (typeof window !== 'undefined') {
    window.location.href = await redirectPath
  }
}

type RedirectProps = {
  redirectPath: Promise<string> | string | null;
  isRedirecting: boolean;
  children?: React.ReactNode
}

export default function Redirect ({
  redirectPath,
  isRedirecting,
  children,
}: RedirectProps): JSX.Element {
  if (redirectPath) {
    redirect(redirectPath)
    return (
      <Box
        style={{ minHeight: '25rem', paddingTop: '9rem' }}
        textAlign='center'
      >
        <Spinner size='super' accessibleText='Loading...' />
      </Box>
    )
  }
  if (isRedirecting) {
    return (
      <Box
        style={{ minHeight: '25rem', paddingTop: '9rem' }}
        textAlign='center'
      >
        <Spinner size='super' accessibleText='Loading...' />
      </Box>
    )
  }

  return children as JSX.Element
}
