import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import YelpPdpPage from './yelp-pdp'

export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)

  useSwanStyleKeys(['core', 'carousel', 'hidden', 'visible', 'banner', 'accordion'])

  return (
    <YelpPdpPage pageContext={props} />
  )
}
