import { HighlightCard } from './highlight-card'
import { HighlightCardProps } from './types'
import { CarouselSlide } from '@vp/swan'

export const HighlightCarouselSlide = ({ icon, title, description }:HighlightCardProps) => {
  return (
    <CarouselSlide aria-roledescription='slide' className='section2__carousel-slide'>
      <HighlightCard icon={icon} title={title} description={description} />
    </CarouselSlide>
  )
}
