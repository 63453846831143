import Redirect from '../common/ui/redirect'
import Section1 from './_components/section1'
import Section2 from './_components/section2'
import Section3 from './_components/section3'
import Section4 from './_components/section4'
import Section5 from './_components/section5'
import { YelpPdpPageDataModel } from './models/yelpPdp/yelpPdpPageData.model'
import { stylesheet as yelpPdpPageStylesheet } from './yelpPdpPage.scss'
import { useStyles } from '@vp/ubik-context'
import { MainContent } from '../common/ui/main-content'
import { PageBreadcrumbs } from './_components/breadcrumbs'
import { ScreenClassProvider } from '@vp/swan'

const YelpPdpPage = ({ pageContext }: any) => {
  useStyles(yelpPdpPageStylesheet)

  const { yelpPdpContent, breadcrumbs, locale } =
    pageContext.pageData as YelpPdpPageDataModel

  const section1Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section1')
  )
  const section2Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section2')
  )
  const section3Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section3')
  )
  const section4Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section4')
  )
  const section5Content = yelpPdpContent.features.filter((feature) =>
    feature.key?.toLowerCase().includes('section5')
  )

  return (
    <>
      <Redirect isRedirecting={false} redirectPath={null}>
        <ScreenClassProvider>
          <MainContent
            pt={4}
          >
            <PageBreadcrumbs breadcrumbs={breadcrumbs} />

            <Section1 content={section1Content} />
            <Section2 content={section2Content} />
          </MainContent>

          <Section3 content={section3Content} locale={locale} />

          <MainContent>
            <Section4 content={section4Content} locale={locale} />
          </MainContent>

          <Section5 content={section5Content} locale={locale} />

        </ScreenClassProvider>
      </Redirect>

    </>
  )
}

export default YelpPdpPage
