// Analytics related to the Yelp PDP page
import {
  analyticsDataRegistry,
  PageAnalyticsData,
  TrackingAction,
  trackNavigationEvent,

} from '@vp/digital-tracking-lib-ubik'

const { yelpPdpPage } = analyticsDataRegistry

const action = TrackingAction.NAVIGATION_CLICKED

export function trackCtaClick (navigationDetail: string, section: string): void {
  const label = `Content:${section}`

  trackNavigationEvent(
    action,
    label,
    yelpPdpPage as PageAnalyticsData, // source
    yelpPdpPage as PageAnalyticsData, // target
    section,
    navigationDetail
  )
}
