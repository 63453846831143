import { Card, P, useScreenClass } from '@vp/swan'
import { HighlightCardProps } from './types'

export const HighlightCard = ({ icon, title, description }:HighlightCardProps) => {
  const screenClass = useScreenClass()
  const isMobile = screenClass === 'xs'

  return (
    <Card evenHeight={!isMobile} bordered className='section2__card'>
      <img src={icon} alt='icon' width={50} height={50} />
      <P fontSize='x3large' fontWeight='bold' className='text-red'>{title}</P>
      <P fontSize='x2large' fontWeight='bold'>{description}</P>
    </Card>
  )
}
