import { H2, Accordion, BasicCollapsible, P, Ol, Li } from '@vp/swan'

export const Faq = () => {
  return (
    <>
      <H2 className='text-center' mt={8} mb={4}>Frequently asked questions</H2>

      <Accordion defaultExpandedCollapsibles={{ 'item-1': true }}>
        <BasicCollapsible collapsibleId='item-1' heading='What does Yelp do?'>
          <P>Yelp connects people with great local businesses. From restaurants to home services, salons, gyms and spas, Yelp is the go-to destination to help customers discover local brands and share their experience of that brand by leaving a Yelp review. With over 76 million people visiting Yelp’s website every month and over 287 million reviews, Yelp helps businesses to get noticed, discovered and remembered.</P>
        </BasicCollapsible>
        <BasicCollapsible collapsibleId='item-2' heading='Why has VistaPrint partnered with Yelp?'>
          <P>Since 1995, VistaPrint has been here to help support small businesses. Today, it’s never been more important for businesses to build brand trust, connect with customers and share their story with the world. Yelp makes that simple, fast and easy. By creating a free Yelp listing, new (and old) customers can share a review, add photos of their experience with your business (like an amazing meal they enjoyed or a special spa session they loved), share your page with friends and family, view your menu or list of services and so much more. They can also find out more about your business including your location, your operating hours, the amenities you offer, your story and your overall rating. VistaPrint’s partnership with Yelp also means you can access a range of solutions and services that can help your business to grow like Yelp Ads and Yelp Guest Manager. To find out more about ‘Yelp for business’, you can head here.</P>
        </BasicCollapsible>
        <BasicCollapsible collapsibleId='item-3' heading='How can I claim my $300 Yelp Ads credit? Are there any terms and conditions that apply to this offer?'>
          <P>You can get $300 of upfront credit against your purchase of qualifying Yelp products. Once your credit has been used, regular charges will apply. This offer cannot be combined with any other offer/s. Any unused credit will expire within 90 days from the date the offer is activated. This offer only applies to eligible businesses. For any questions or queries relating to this offer, you can contact Yelp.</P>
        </BasicCollapsible>
        <BasicCollapsible collapsibleId='item-4' heading='Where is your data from?'>
          <Ol className='list--section5'>
            <Li textColor='info'>Yelp Internal Data, 2022. Based on an analysis of businesses that added a photo, URL, phone number and business hours to their business page between March 2021-March 2022.</Li>
            <Li textColor='info'>As of December 31, 2024, Yelp users have contributed a total of 308 million cumulative reviews. Contributed reviews include those that are recommended, not recommended, or removed from Yelp's platform.</Li>
            <Li textColor='info'>Comscore, monthly average for 2023.</Li>
            <Li textColor='info'>Based on a compensated survey conducted by Yelp in October 2024. Methodology used Comscore demographics and was based on people who reported having used Yelp in the prior 3 months.</Li>
            <Li textColor='info'>Yelp Internal Data, 2024. Based on an analysis conducted between January to December 2024.</Li>
          </Ol>
        </BasicCollapsible>
      </Accordion>
    </>
  )
}
