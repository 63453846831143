import { Hidden, Carousel, Column, GridContainer, Row } from '@vp/swan'
import { HighlightCard } from './highlight-card'
import { HighlightCarouselSlide } from './highlight-carousel-slide'

export const Hightlights = () => {
  return (
    <>
      <Hidden xs>
        <GridContainer mt={6} gap={5}>
          <Row>
            <Column span={4}>
              <HighlightCard
                icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-search-circle.svg'
                title='76m+'
                description='people visit Yelp each month to find a service or business'
              />
            </Column>
            <Column span={4}>
              <HighlightCard
                icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-users-circle.svg'
                title='82%'
                description='of users hire or buy from businesses found on Yelp'
              />
            </Column>
            <Column span={4}>
              <HighlightCard
                icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-document-circle.svg'
                title='85k+'
                description='daily requests for quotes and consultations on Yelp'
              />
            </Column>
          </Row>
        </GridContainer>
      </Hidden>

      <Hidden sm md lg xl>
        <Carousel
          slidesToShow={1}
          progressIndicator='dots'
          aria-label='A carousel with dots'
          accessibleTextForPrevious='Go to previous slide'
          accessibleTextForNext='Go to next slide'
          accessibleTextForDots={['Slide One', 'Slide Two', 'Slide Three']}
          accessibleTextForVisibleSlides='Slide {activeSlides} of {totalSlides}'
          aria-roledescription='carousel'
          mt={6}
        >
          <HighlightCarouselSlide
            icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-search-circle.svg'
            title='76m+'
            description='people visit Yelp each month to find a service or business'
          />
          <HighlightCarouselSlide
            icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-users-circle.svg'
            title='82%'
            description='of users hire or buy from businesses found on Yelp'
          />
          <HighlightCarouselSlide
            icon='https://cms.cloudinary.vpsvc.com/image/upload/v1742949842/digital/promotions/yelp-pdp/section2/icon-document-circle.svg'
            title='85k+'
            description='daily requests for quotes and consultations on Yelp'
          />
        </Carousel>
      </Hidden>
    </>
  )
}
