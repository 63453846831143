import { Box, H2 } from '@vp/swan'
import { SectionProps } from '../types'
import { Faq } from './faq'
import { ActionCards } from './action-cards'
import { MainContent } from '../../../common/ui/main-content'

const Section5 = ({ content, locale }: SectionProps) => {
  return (
    <Box className='block--section5 bg-warm-white' py={{ xs: 4, sm: 8 }} mt={{ xs: 10, md: 12 }}>
      <MainContent>
        <H2 className='text-center' mt={8}>Turn new customers into loyal fans</H2>

        <ActionCards locale={String(locale)} />

        <Faq />
      </MainContent>
    </Box>
  )
}

export default Section5
