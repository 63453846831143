import { trackCtaClick } from './YelpPdpPageAnalytics'

// Declare the global 'window' object
declare const window: any

export const redirectWithTracking = (
  path: string,
  label: string,
  section: string,
  isExternal: boolean = false
): void => {
  trackCtaClick(label, section)
  setTimeout(() => {
    if (isExternal && typeof window !== 'undefined') window.open(path, '_blank')
    else window.location.href = path
  }, 800)
}
