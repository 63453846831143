import { Button, Card, Icon, P } from '@vp/swan'
import { redirectWithTracking } from '../../../helpers/redirectWithTracking'

interface ActionCardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  description: string;
  linkText: string
  linkUrl: string
}
export const ActionCard = ({ imgSrc, imgAlt, title, description, linkText, linkUrl = '#' }:ActionCardProps) => {
  return (
    <Card skin='link' className='section5__card'>
      <img src={imgSrc} alt={imgAlt} width={325} height={325} />
      <P fontSize='large' fontWeight='bold'>{title}</P>
      <P fontSize='small'>{description}</P>
      <Button
        skin='link'
        fontWeight='bold'
        covering href={linkUrl} onClick={() =>
          redirectWithTracking(
            linkUrl,
            linkText,
            'Hero',
            true
          )}
      >{linkText} <Icon iconType='arrowRight' />
      </Button>

    </Card>
  )
}
