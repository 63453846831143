import {
  Banner,
  BannerDescription,
  BannerImage,
  BannerImageContainer,
  BannerText,
  BannerTextContainer,
  BannerTitle,
  FluidImage,
  Li,
  P,
  Ul,
} from '@vp/swan'
import { SectionProps } from './types'

const Section3 = ({ content, locale }: SectionProps) => {
  return (
    <Banner className='block--section3 bg-warm-white' mt={10} variant='card' height='short'>
      <BannerTextContainer>
        <BannerText>
          <BannerTitle>VistaPrint and Yelp explained</BannerTitle>
          <BannerDescription>
            <P mt={6}>
              What happens when Yelp’s marketing tools combine with VistaPrint's range of digital and marketing services (like logos, business cards and so much more)? Your business gets the attention it deserves. Here’s how:
            </P>
            <Ul skin='checkmark'>
              <Li mt={2} className='text-start'>Get discovered by a whole new crowd with Yelp</Li>
              <Li mt={{ xs: 2, md: 4 }} className='text-start'>Print your brand onto (almost) anything with VistaPrint</Li>
              <Li mt={{ xs: 2, md: 4 }} className='text-start'>Grow your business online and off with Yelp and VistaPrint </Li>
            </Ul>
          </BannerDescription>
        </BannerText>
      </BannerTextContainer>
      <BannerImageContainer>
        <BannerImage>
          <FluidImage
            src='https://cms.cloudinary.vpsvc.com/image/upload/v1742998094/digital/promotions/yelp-pdp/section3/section3-img.webp'
            alt='background'
          />
        </BannerImage>
      </BannerImageContainer>
    </Banner>
  )
}

export default Section3
